
import { Component, VModel, Vue } from "vue-property-decorator";
import { Factcheck } from '@/types';

@Component
export default class FactcheckForm extends Vue {
    @VModel() factcheck!: Factcheck;

    types = [
        'Factcheck',
        'Debunk',
        'Narrative',
        'Prebunk',
    ]

}

